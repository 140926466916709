import _ from "lodash";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import React, { useState } from "react";
import PageContainer from "../components/layout/PageContainer";
import Nav from "../components/navigation/Nav";
import { theme } from "../themes/themes";
import { useAllProducts } from "../utils/useAllProducts"
import Seo from "../components/Seo"
import PageHeroDescription from "../components/PageHeroDescription"
import { screenSizesDescription } from "../constants/screensizes"
import CompareButton from "../components/navigation/CompareButton";
import GroupableDeviceGrid from "../templates/GroupableDeviceGrid";
import Footer from "../components/navigation/Footer";

const Breakpoints = ({
    location
}) => {
    const { products } = useAllProducts();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Seo location={location} />
            <Nav location={location}>
                <CompareButton />
            </Nav>
            <PageContainer>
                <PageHeroDescription
                    description={screenSizesDescription}
                />
                <GroupableDeviceGrid
                    products={products}
                />
                <Footer />
            </PageContainer>
        </ThemeProvider>
    )
}

export default Breakpoints;